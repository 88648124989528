import { Outlet } from "react-router-dom";
import AppAdvertiesment from "./common/appAdvertiesment";
import Footer from "./common/footer";
import Header from "./common/header";

function MenuPage() {
    return (<>
        <Header></Header>
            <Outlet />
            <AppAdvertiesment></AppAdvertiesment>
        <Footer></Footer>
    </>);
}
export default MenuPage;