import { useEffect } from "react";
import { CreateCustomerProfile } from "../services/Api";
// import { selectAnswerMap, updateAnswerMap } from "./redux/answerMapReducer";
// import { selectDisableMap } from "./redux/disableMapReducer";
import { useAppDispatch } from "../hooks";
import { BootstrapInput, useFormInput } from "../events/CodeHelper";
import { InputLabel } from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { useNavigate } from "react-router-dom";
import { setDataCustomerProfile } from "./redux/customerProfileReducer";
import { withSnackbar } from "../events/hooks/useNotification";
import InputAdornment from "@mui/material/InputAdornment";

var nameGlobal = '';
var ageGlobal = '';
var mobileGlobal = '';
var emailGlobal = '';
var genderGlobal = '';
var liabilitiesGlobal = '';
var incomeGlobal = '';
var marriedGlobal = '';
var spouseWorkingGlobal = '';
function Home(props: any) {
    const name = useFormInput(nameGlobal);
    const age = useFormInput(ageGlobal);
    const mobile = useFormInput(mobileGlobal);
    const email = useFormInput(emailGlobal);
    const gender = useFormInput(genderGlobal);
    const liabilities = useFormInput(liabilitiesGlobal);
    const income = useFormInput(incomeGlobal);
    const married = useFormInput(marriedGlobal);
    const spouseWorking = useFormInput(spouseWorkingGlobal);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        // console.log(name.value);
        // // if (!form)
        // //     getFormData(0);
        // name.onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        //     console.log(e);
        // }
    });

    const checkValidation = () => {
        if (isNaN(Number(age.value)) || isNaN(Number(income.value)) || isNaN(Number(liabilities.value)) || isNaN(Number(mobile.value))) {
            return {'error':true,'msg':'Please fill valid value'}
        }
        if (Number(age.value) > 100) {
            return { 'error': true, 'msg': 'Please fill age correctly' }
        }
        if (Number(liabilities.value) > Number(income.value)) {
            return { 'error': true, 'msg': "Liabilities can't exceed income" }
        }
        if (mobile.value.length > 10) {
            return { 'error': true, 'msg': 'Please fill valid mobile no' }
        }
        return { 'error': false, 'msg': 'Please fill valid value' }
    }


    const submit = () => {
        console.log(name.value, age.value, gender.value, mobile.value, email.value, spouseWorking.value, married.value);
        if (name.value && age.value && mobile.value && email.value && income.value && liabilities.value) {
            let validationResp = checkValidation();
            if (!validationResp.error) {
                CreateCustomerProfile(name.value, age.value, income.value, mobile.value, email.value, liabilities.value, (resp: any) => {
                    console.log(resp);
                    if (!resp.error) {
                        nameGlobal = name.value;
                        ageGlobal = age.value;
                        genderGlobal = gender.value;
                        mobileGlobal = mobile.value;
                        emailGlobal = email.value;
                        incomeGlobal = income.value;
                        liabilitiesGlobal = liabilities.value;
                        dispatch(setDataCustomerProfile({ key: '', value: resp['resp'] }))
                        navigate('./result', { replace: true });
                    } else {
                        props.snackbarShowMessage('Something went wrong!!', 2000);
                    }
                });
            } else {
                props.snackbarShowMessage(validationResp.msg, 2000);
            }

        } else {
            props.snackbarShowMessage('Please fill data!!', 2000);
        }
    }

    // const changeWidgetInput = (op: Answer) => {
    //     if (op.value) {
    //         dispatch(updateAnswerMap({ key: op.fieldNo, value: op }))
    //         // answerMap.set(op.fieldNo, op);
    //         // answerMap = new Map(this.answerMap);
    //         // if (this.formData?.filledByInfos)
    //         //     this.calculatedFillebyinfo(this.formData?.filledByInfos);
    //     }
    // }
    // const formValid = (ev: FieldInfo) => {
    //     // validated = { ...validated, ...ev };
    //     // let checkvalid = true;
    //     // isValid = checkvalid;
    //     // for (const key in validated) {
    //     //     if (validated[key] == 'inValid' && checkvalid) {
    //     //         checkvalid = false;
    //     //         isValid = checkvalid;
    //     //         return;
    //     //     }
    //     // }

    // }
    // const addFieldasValid = (fieldNo: number) => {
    //     let ev: FieldInfo = {}
    //     ev[fieldNo] = 'valid';
    //     formValid(ev);
    // }

    // const removeinput = (fieldid: number) => {
    //     // dispatch(removeFieldAnswerMap({ key: fieldid, value: {} }));
    //     // answerMap.delete(fieldid);
    //     // answerMap = new Map(answerMap);
    // }

    // const calculatedFillebyinfo = (filledByInfos: Array<Question>) => {
    //     let array: Array<Question> = [];

    //     filledByInfos.forEach((element, index) => {


    //         const ans = answerMap.get(element.fieldNo);
    //         if (ans && element.configId && !ans?.configId) {
    //             ans.configId = element.configId; // to set config id in answermap for prefilled id 
    //             changeWidgetInput(ans);
    //         }

    //         if (element.fieldNo === 265) {

    //             const getAnswerState = answerMap.get(6);
    //             if (getAnswerState) {
    //                 if (element.configId !== 'state_' + getAnswerState?.value + '_county') {
    //                     element.configId = 'state_' + getAnswerState?.value + '_county';
    //                 }
    //             }
    //         }
    //         if (element.fieldNo === 239) {
    //             const getAnswerState = answerMap.get(6);
    //             let getAnswerCounty = answerMap.get(265);
    //             //check in conditionalvalue
    //             if (!getAnswerCounty) {
    //                 answerMap.forEach((value: any, key: any) => {
    //                     if (value.conditionFieldNo === 265) {
    //                         getAnswerCounty = value;
    //                     }
    //                 });
    //             }

    //             if (getAnswerCounty) {
    //                 if (element.configId !== `county_${getAnswerCounty?.altValue}_zipcode`) {
    //                     element.configId = `county_${getAnswerCounty?.altValue}_zipcode`;
    //                 }
    //             } else if (getAnswerState) {
    //                 if (element.configId !== `state_${getAnswerState?.value}_zipcode`) {
    //                     element.configId = `state_${getAnswerState?.value}_zipcode`;
    //                 }
    //             } else {

    //             }
    //         }
    //         if (element?.visibilityCondition?.visibleType === 'SHOWN') {
    //             const hidden = checkVisibilityCondition(element, answerMap, "SHOWN");
    //             if (!hidden) {
    //                 array.push(element);
    //             } else {
    //                 removeinput(element.fieldNo);
    //                 addFieldasValid(element.fieldNo);
    //             }
    //         } else if (element?.visibilityCondition?.visibleType === 'HIDDEN') {
    //             const shown = checkVisibilityCondition(element, answerMap, "SHOWN");
    //             if (!shown) {
    //                 removeinput(element.fieldNo);
    //                 addFieldasValid(element.fieldNo);
    //             } else {
    //                 array.push(element);
    //             }
    //         } else {
    //             array.push(element);
    //         }
    //         if (element?.visibilityCondition?.visibleType === 'DISABLED') {
    //             const shown = checkVisibilityCondition(element, answerMap, "DISABLED");
    //             if (!shown) {
    //                 // disable true add in map
    //                 disableMap.set(element?.fieldNo, true);
    //             } else {
    //                 disableMap.set(element?.fieldNo, false);
    //             }
    //         }
    //         if (element?.visibilityCondition?.visibleType === 'ENABLED') {
    //             const shown = checkVisibilityCondition(
    //                 element,
    //                 answerMap,
    //                 'ENABLED'
    //             );
    //             if (shown) {
    //                 // disable true add in map
    //                 // disableMap.set(element?.fieldNo, true);
    //                 disableMap.set(element?.fieldNo, false);
    //             } else {
    //                 disableMap.set(element?.fieldNo, false);
    //             }
    //         }
    //     });
    //     console.log(array)
    //     // setcalculatedFilledbyinfoArray(array)
    //     //  calculatedFilledbyinfoArray = array;
    //     // this.isLoading = false;
    //     // this.progressBar.show = true;
    //     // this.showData = true;
    // }


    // const getFormData = (step: number) => {
    //     getForm((resp: FormData) => {
    //         console.log('18', resp);

    //         setForm(resp);
    //         console.log(' resp?.pages[value]', resp?.pages || '', step);
    //         // console.log('111', resp?.pages[step - 1]);
    //         // if (step === 1) {
    //         calculatedFillebyinfo(resp.filledByInfos || [])
    //         // }
    //         if (step > 0 && resp?.pages && resp?.pages[step - 1]) {
    //             // dispatch(initializeForm(resp?.pages[step - 1]))
    //             setCurrentPageData(resp?.pages[step - 1])
    //         }
    //     })
    // }
    return (
        <div className="px-12 ">
            <div className="md:container  md:mx-auto  flex flex-col   bg-white drop-shadow-xl rounded-xl -mt-16 overflow-hidden border border-amber-300	 ">
                <div className="bg-amber-300 w-full py-3 text-2xl text-black"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-9 h-9 mr-2 inline-block ">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                    Investor Basic Info</div>
                <div className="grid  grid-cols-1  sm:grid-cols-3 gap-12  px-16 pt-12 pb-4 form1 ">
                    <div className="">
                        <InputLabel htmlFor="name" className="text-left text-base">Name </InputLabel>
                        <BootstrapInput required defaultValue="demo" id="name" className="input-fm1" fullWidth type="text" margin="dense"  {...name} />

                    </div>
                    <div className=" text-base">
                        <InputLabel htmlFor="age" className="text-left text-base ">  Age <sub>in years</sub> </InputLabel>
                        <BootstrapInput required defaultValue="30" inputProps={{ min: "0", max: "100", step: "1", inputMode: 'numeric', pattern: '[0-9]*' }} id="age" className="input-fm1" fullWidth type="number" margin="dense"  {...age} />
                    </div>
                    <div className="text-base">
                        <InputLabel htmlFor="mobile" className="text-left text-base"> Mobile </InputLabel>
                        <BootstrapInput required defaultValue="" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} id="mobile" className="input-fm1" fullWidth type="number" margin="dense"  {...mobile} />
                    </div>
                    <div className="text-base">
                        <InputLabel htmlFor="email" className="text-left text-base">  Email </InputLabel>
                        <BootstrapInput required defaultValue="" id="email" className="input-fm1" fullWidth type="text" margin="dense"  {...email} />
                    </div >
                    <div className=" text-base">
                        <InputLabel htmlFor="income" className="text-left text-base "> Please input your monthly salary here​</InputLabel>
                        <BootstrapInput endAdornment={<InputAdornment position="end">
                            <div className="text-xl">&#8377;</div>
                        </InputAdornment>} required defaultValue="30" id="income" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} className="input-fm1" fullWidth type="number" margin="dense"  {...income} />
                    </div>
                    <div className="text-base">
                        <InputLabel htmlFor="liabilities" className="text-left text-base ">
                            How much goes into paying back liabilities/dependencies?
                        </InputLabel>
                        <BootstrapInput endAdornment={<InputAdornment position="end" className="ml-2 text-semibold">
                            <div className="text-xl">&#8377;</div>
                        </InputAdornment>} required defaultValue="30" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} id="liabilities" className="input-fm1" fullWidth type="number" margin="dense"  {...liabilities} />
                    </div>
                    {/*                 
                        <div className="text-left mb-10">
                        <InputLabel id="gender" className="text-left text-base">Gender </InputLabel>
                        <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                                {...gender} row={true}
                            >
                                <FormControlLabel value="female" control={<Radio />} label="Female" />
                                <FormControlLabel value="male" control={<Radio />} label="Male" />
                            </RadioGroup>
                        </div> */}
                    <div className="text-left option-col  ">
                        <InputLabel id="gender" className="text-left text-base ">Are you married?​ </InputLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="yes" className="flex flex-row"
                            name="radio-buttons-group" row={true}
                            {...married}
                        >
                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio />} label="No" />
                        </RadioGroup>
                    </div>
                    <div className="text-left option-col">
                        {married.value === 'yes' &&
                            <><FormLabel id="gender">Do you have a working spouse?​</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="yes" row={true}
                                    name="radio-buttons-group"
                                    {...spouseWorking}
                                >
                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup></>
                        }
                    </div>
                </div>
                <div className={`mt-6 py-2 px-8 mb-10 text-black font-semibold btn rounded-full text-lg mx-auto  ${name.value !== '' && age.value !== '' && mobile.value !== '' && email.value !== '' && income.value !== '' ? "cursor-pointer  bg-amber-300 text-black " : " bg-gray-200 cursor-pointer-none"}`}
                    onClick={submit}>SUBMIT</div>
            </div>
        </div>  
    );
}
export default withSnackbar(Home);

