import React, { useState } from 'react';
import InputLabel from "@mui/material/InputLabel";
import { BootstrapInput, roundNumber, useFormInput } from "../events/CodeHelper";
import { useAppSelector } from "../hooks";
import { selectCustomerProfile } from "./redux/customerProfileReducer";
import { useEffect } from "react";
import { getMutualFund } from '../services/Api';
import { withSnackbar } from '../events/hooks/useNotification';
import { TopFunds } from '../events/Model';
import { useNavigate } from 'react-router-dom';

function Result(props: any) {
    const customerProfile = useAppSelector(selectCustomerProfile);
    const amountToInvest = useFormInput('');
    const navigate = useNavigate();
    const [mutualFundResp, setMutualFundResp] = useState<TopFunds>();
    useEffect(() => {

        console.log("customerProfile", customerProfile, mutualFundResp);

    })

    const back = () => {
        navigate('../', { replace: true });
    }
    const submit = () => {
        console.log(amountToInvest.value);
        if (amountToInvest.value) {
            getMutualFund(amountToInvest.value, customerProfile?.id || '', (resp: any) => {
                console.log(resp);
                if (!resp.error) {
                    setMutualFundResp(resp['resp']);
                    console.log(mutualFundResp);
                } else {
                    props.snackbarShowMessage('Something went wrong!!', 2000);
                }
            });
        } else {
            props.snackbarShowMessage('Please fill Amount!!', 2000);
        }
    }

    return (<>
        <div className="md:container  md:mx-auto  flex flex-col  bg-white drop-shadow-xl rounded-xl -mt-16 overflow-hidden border border-amber-300	 ">
            <div className="bg-amber-300 w-full py-3 text-2xl text-black">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-9 h-9 mr-2 inline-block -mt-1">
                    <path strokeLinecap="round" stroke-linejoin="round" d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z" />
                </svg>Results</div>
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-12 p-16  pb-2'>
                <div>
                    <span className='  text-lg'>Your risk profile under the</span>
                    <div className={`p-4 py-5  mt-3 font-light flex items-center justify-center rounded-lg  text-5xl shadow ${customerProfile?.natureType === 'MODERATE' ? "bg-green-200 text-green-800" : " "} ${customerProfile?.natureType === 'CONSERVATIVE' ? "bg-red-200 text-red-800" : " "} ${customerProfile?.natureType === 'AGGRESSIVE' ? "bg-red-200 text-red-800" : " "} ${customerProfile?.natureType === 'UNKNOWN ' ? "bg-cyan-200 text-cyan-800" : " "}`}  >
                        {customerProfile?.natureType}
                    </div>
                </div>
                <div className="flex flex-col items-center ">
                    <InputLabel htmlFor="amountToInvest" className=" fmresult text-black  "> I wish to invest per month​</InputLabel>
                    <BootstrapInput id="amountToInvest" fullWidth type="number" placeholder='Please enter the amount ' className="input-fm1 in-wdth" margin="dense"  {...amountToInvest} />
                </div>
            </div>
            <div className='px-16 flex flex-row justify-between mb-8 mt-4 items-end'>
                <div className="py-2 px-8 text-black font-semibold btn bg-amber-300 rounded-full text-lg  mx-2 cursor-pointer" onClick={back}>Back</div>
                <div className={`py-2 px-8 mb-0 text-black font-semibold btn rounded-full text-lg mx-2  ${amountToInvest.value !== '' ? "active cursor-pointer bg-amber-300" : "cursor-pointer-none bg-gray-100 "}`} onClick={submit}>Next</div>
            </div>
            <div>
                {mutualFundResp?.fundsDetail &&
                    <div className=''>
                        <h2 className='text-4xl text-black w-full col-span-3 pl-16	text-left'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-10 h-10 text-gray-900 -mt-1 inline-block mr-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                            </svg> 
                            Plan Your Investment
                        </h2>
                        <div className='border mt-8 border-blue-200 mx-16 rounded shadow-lg'>
                    
                        <div className='grid grid-cols-1  sm:grid-cols-1 gap-12 p-14 justify-items-start '>
                        <div className='text-3xl bg-white col-auto   text-sky-900  font-semibold'>Option 1</div>
                        {mutualFundResp?.fundsDetail.FIRST_TOP_FUNDS.map(funds => {

                            return <div className={`px-8 py-6 flex flex-col justify-start w-full  rounded-lg shadow-lg border-white border-2 text-center ${funds['Category Name'] === 'Large Cap Fund' ? "bg-violet-100 border-violet-200		" : ""} ${funds['Category Name'] === 'Mid Cap Fund' ? "bg-orange-100 border-orange-200	" : ""} ${funds['Category Name'] === 'Small Cap Fund' ? "bg-teal-100 border-teal-200" : ""}`}    >
                                <div className='text-left text-4xl font-bold border-b border-white  pb-4 mb-4'>
                                    <span className='text-4xl text-black'>
                                        &#8377;
                                    </span>
                                    {mutualFundResp.fundsDetail.FIRST_TOP_FUNDS.length > 0 ? roundNumber(mutualFundResp.amountDistribution![0]![funds['Category Name']]) : ''}
                                </div>
                                <div className='text-left'>{funds['Scheme Name']}</div>
                                <div className='text-left bg-white shadow rounded-full w-40 px-3 font-semibold text-center mt-3 py-1 inline-block'>{funds['Category Name']}</div>
                            </div>
                        })}
                        </div>
                        </div>

                        <div className='border my-10 border-blue-200 mx-16 rounded shadow-lg'>
                        <div className='grid grid-cols-1  sm:grid-cols-1 gap-12 p-14 justify-items-start '>
                        <div className='text-3xl bg-white col-auto  text-sky-900  font-semibold'>Option 2</div>
                        {mutualFundResp?.fundsDetail.SECOND_TOP_FUNDS.map(funds => {

                            return <div className={`px-8 py-6 flex flex-col justify-start w-full  rounded-lg shadow-lg border-white border-2 text-center ${funds['Category Name'] === 'Large Cap Fund' ? "bg-violet-100 border-violet-200		" : ""} ${funds['Category Name'] === 'Mid Cap Fund' ? "bg-orange-100 border-orange-200	" : ""} ${funds['Category Name'] === 'Small Cap Fund' ? "bg-teal-100 border-teal-200" : ""}`}    >
                                <div className='text-left text-4xl font-bold border-b border-white  pb-4 mb-4'>
                                    <span className='text-4xl text-black'>
                                        &#8377;
                                    </span>
                                    {mutualFundResp.fundsDetail.SECOND_TOP_FUNDS.length > 0 ? roundNumber(mutualFundResp.amountDistribution![0]![funds['Category Name']]) : ''}
                                </div>
                                <div className='text-left'>{funds['Scheme Name']}</div>
                                <div className='text-left bg-white shadow rounded-full w-40 px-3 font-semibold text-center mt-3 py-1 inline-block'>{funds['Category Name']}</div>
                            </div>
                        })}
                        </div>
                        </div>
                    </div>}
            </div>
            {/* <div className='flex flex-row justify-center mb-8'>
                <div className="py-2 px-12 mb-10 text-black font-semibold btn bg-amber-300 rounded-full text-lg  mx-2 cursor-pointer" onClick={back}>Back</div>
            </div> */}
        </div>
    </>);
}

export default withSnackbar(Result);