import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./ui/auth/Login";
import NoPage from "./ui/NoPage";
import Register from "./ui/auth/Register";
import MenuPage from "./ui/MenuPage";
import Home from "./ui/home";
import eventBus from "./events/event";
import Result from "./ui/result";

function Approuting() {
    return (
        <main>
            {/* <BrowserRouter basename="path2"> */}
            <BrowserRouter  >
                <Routes>
                    <Route path="/home" element={<MenuPage />}>
                        <Route index element={<RequireAuth>
                            <Home />
                        </RequireAuth>
                        } />
                        <Route path="result" element={<RequireAuth><Result /></RequireAuth>} />

                        {/* <Route path="validate/:uniqueID" element={<Home/>}/>
                        <Route path="test" element={<RequireAuth><Test /></RequireAuth>} /> */}
                        <Route path="*" element={<RequireAuth><NoPage /></RequireAuth>} />
                    </Route>
                    <Route path="*" element={<NoPage />} />
                    <Route path="/" element={<Login />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                </Routes>
            </BrowserRouter>
        </main>
    );
}

export default Approuting;


function RequireAuth({ children }: any) {
    // let location = useLocation();
    let [isLogin, setIslogin] = useState(localStorage.getItem("isLogin"));
    eventBus.on("loginChanged", (data) =>
        setIslogin(localStorage.getItem("isLogin"))
    );
    if (isLogin !== 'true') {
        // return <Navigate to="../login" state={{ from: location }} replace />;
    }
    return children;
}


