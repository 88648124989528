function Header() {
    return (<>
        <div className="bg-sky-900  text-white ">
            <div className="md:container px-12 py-2 mx-auto flex flex-row  justify-between items-center">
                <div className="">
                    <img src="https://cdn.virtueanalytics.com/cache/finlit-logo.png" alt="" className="w-64"></img>
                </div>
                <div className=" flex flex-row items-center" >
                    <a href="https://play.google.com/store/apps/details?id=com.thefinlitproject.app&pli=1" target="_blank" rel="noreferrer">
                        <img src="https://cdn.virtueanalytics.com/cache/apple-store-btn.png" className="" alt=""></img>
                    </a>
                    <a href="https://apps.apple.com/app/id1576101765#?platform=iphone" rel="noreferrer" target="_blank" className="mx-4">
                        <img src="https://cdn.virtueanalytics.com/cache/google-play-btn.png" alt="" className=""></img></a>
                    <a href="https://www.thefinlitproject.com/" target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-white">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
        <div className=" bg-sky-100 bg-blue-banner shadow-lg   ">
            <div className="lg:container mx-auto  grid grid-cols-2 gap-4 px-12">
                <div className="text-left font-light	 text-2xl text-sky-900 xl-mr-24 xl:ml-8 pt-12 pb-24 w-8/12	">Financial literacy is not an end in itself, but a step-by-step process.  It begins in childhood and continues throughout a  person's life all the way to retirement. </div>
                <div className="relative  text-center"><img alt="" src="https://cdn.virtueanalytics.com/cache/thefinlitproje-banner-img.png" className="w-96  mx-auto"></img></div>
            </div>
        </div>
    </>
    );
}
export default Header;