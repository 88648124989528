// export enum Events {
//     SET_TITLE = 'set_title'
//     // use an enum to keep track of events similar to action types set as variables in redux
// }
// export const eventEmitter = {
//     _events: {},
//     dispatch(event: Events, data: any) {
//         if (!this._events.[event]) return;
//         this._events[event].forEach((callback: (arg0: any) => any) => callback(data))
//     },
//     subscribe(event: Events, callback: (data: any) => any) {
//         if (!this._events[event]) this._events[event] = [];
//         this._events[event].push(callback);
//     },
//     unsubscribe(event: Events) {
//         if (!this._events[event]) return;
//         delete this._events[event];
//     }
// }


const eventBus = {

    on(event: any, callback: (arg0: any) => any) {

        document.addEventListener(event, (e) => callback(e.detail));

    },

    dispatch(event: string, data: any) {

        document.dispatchEvent(new CustomEvent(event, { detail: data }));

    },

    remove(event: any, callback: (this: Document, ev: any) => any) {

        document.removeEventListener(event, callback);

    },

};


export default eventBus;