
import styled from '@emotion/styled';
import { alpha, createTheme, InputBase } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import Config from '../ui/const/Config';
import { Answer, Question, Widget } from './Model';

export const theme = createTheme({
    palette: {
        primary: {
            // Purple and green play nicely together.
            main: '#308553', //purple[500],
        },
        secondary: {
            // This is green.A700 as hex.
            main: '#fff',
        },
    },
});

export const dateutcIsoFormat = (date: string, format: string) => {
    return moment.utc(date, format).toISOString()
}

export const roundNumber = (number: number) => {
    const num = Math.round(number / 100) * 100;
    return num.toLocaleString('hi-IN');
    // return num.toLocaleString('en-US');

}


export const setUniqueId = (uniqueID: any) => {
    console.log(window.location.href.split("/"));
    const array = window.location.href.split("/");
    Config.preText = `${array[3]}_${array[4]}`;
    localStorage.setItem(`${Config.preText}uniqueId`, uniqueID);
}

export const getUniqueId = () => {
    return localStorage.getItem(`${Config.preText}uniqueId`);
}

export const useFormInput = (initialValue: string) => {
    const [value, setValue] = useState(initialValue);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    }
    return {
        value,
        onChange: handleChange
    }
}

export const checkVisibilityCondition = (item: Question | Widget, answerMap: Map<Number, Answer>, type?: string) => {
    let visiblityMap: Map<number, boolean> = new Map();
    let orvisiblityMap: Map<number, boolean> = new Map();
    if (item && item?.visibilityCondition?.conditions) {
        for (let index = 0; index < item?.visibilityCondition?.conditions.length; index++) {
            const element = item?.visibilityCondition?.conditions[index];
            const fieldid = element.fieldId;
            let comparedfielddata: string = '';
            if (answerMap.get(fieldid)) {
                const ansobject = answerMap.get(fieldid);
                comparedfielddata = ansobject?.value || '';
            }
            visiblityMap.set(index, true);
            if (element.compareWith) {
                if (comparedfielddata === element.compareWith?.extactValue) {
                    visiblityMap.set(index, false);
                } else if (element.compareWith?.contains) {
                    if (comparedfielddata !== '' && element.compareWith?.contains.toString().includes(comparedfielddata)) {
                        visiblityMap.set(index, false);
                    }
                    else if (comparedfielddata !== '' && comparedfielddata === element.compareWith?.contains) {
                        visiblityMap.set(index, false);
                    }
                } else if (element.compareWith?.notContains) {
                    if (comparedfielddata !== '' && !element.compareWith?.notContains.toString().includes(comparedfielddata.toString())) {
                        visiblityMap.set(index, false);
                    } else if (comparedfielddata !== '' && !element.compareWith?.notContains.toString().includes('[') && comparedfielddata !== element.compareWith?.notContains) {
                        visiblityMap.set(index, false);
                    }
                } else if (element.compareWith?.minValue && comparedfielddata && Number(comparedfielddata) > element.compareWith?.minValue) {
                    visiblityMap.set(index, false);
                } else if (element.compareWith?.minValue && Number(comparedfielddata) > element.compareWith?.maxValue) {
                    visiblityMap.set(index, false);
                }
            } else {
                const altval = answerMap.get(fieldid)?.altValue;
                if (!altval || altval === 'undefined')
                    visiblityMap.set(index, false);
            }
        }
    }
    if (item && item?.visibilityCondition?.orConditions) {
        for (let index = 0; index < item?.visibilityCondition?.orConditions.length; index++) {
            const element = item?.visibilityCondition?.orConditions[index];
            const fieldid = element.fieldId;
            let comparedfielddata: string = '';
            if (answerMap.get(fieldid)) {
                const ansobject = answerMap.get(fieldid);
                comparedfielddata = ansobject?.value || '';
            }
            orvisiblityMap.set(index, true);
            if (element.compareWith) {
                if (comparedfielddata === element.compareWith?.extactValue) {// hidden = false;
                    orvisiblityMap.set(index, false);
                } else if (element.compareWith?.contains) {
                    if (comparedfielddata !== '' && JSON.stringify(element.compareWith?.contains).includes(comparedfielddata)) {// hidden =false;
                        orvisiblityMap.set(index, false);
                    }
                    else if (comparedfielddata !== '' && comparedfielddata === element.compareWith?.contains) {
                        orvisiblityMap.set(index, false);
                    }
                } else if (element.compareWith?.notContains) {
                    if (!element.compareWith?.notContains.toString().includes(comparedfielddata.toString())) {
                        orvisiblityMap.set(index, false);
                    } else if (comparedfielddata !== element.compareWith?.notContains) {
                        orvisiblityMap.set(index, false);
                    }
                } else if (element.compareWith?.minValue && comparedfielddata && Number(comparedfielddata) > element.compareWith?.minValue) {
                    orvisiblityMap.set(index, false);
                } else if (element.compareWith?.minValue && Number(comparedfielddata) > element.compareWith?.maxValue) {
                    orvisiblityMap.set(index, false);
                }
            } else {
                const altval = answerMap.get(fieldid)?.altValue;
                if (!altval || altval === 'undefined')
                    visiblityMap.set(index, false);
            }
        }
    }


    let hidden = false;
    let con = false;
    visiblityMap.forEach((el) => {
        if (el && !con)
            con = true;
    });
    let orcon = true; /// if one is false then false

    if (orvisiblityMap.size > 0)
        orvisiblityMap.forEach((el) => {
            if (!el && orcon)
                orcon = false;
        });
    else
        orcon = false;
    hidden = con || orcon;
    return hidden;
}


export const BootstrapInput = styled(InputBase)(({ theme: any }) => ({
    'label + &': {
        marginTop: theme.spacing(0),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        border: '1px solid #ced4da',
        fontSize: 16,
        width: '100%',
        padding: '10px 12px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));