import { createSlice } from '@reduxjs/toolkit';
import {CustomerProfile } from '../../events/Model';
import { RootState } from '../store';



export interface CustomerProfileState {
    value: CustomerProfile | undefined;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: CustomerProfileState = {
    value: undefined,
    status: 'idle',
};

export const CustomerProfileReducer = createSlice({
    name: 'customerProfile',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        initializeAnswerMap: (state, action) => {
            state.value = action.payload;
        },
        setDataCustomerProfile: (state, action) => {
            console.log(state, action);
            state.value = (action.payload.value);
            console.log(state, action, state.value);
        },
    },
});

export const { initializeAnswerMap, setDataCustomerProfile } = CustomerProfileReducer.actions; 
export const selectCustomerProfile = (state: RootState) => state.customerProfile.value;


export default CustomerProfileReducer.reducer;
