import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
 

export interface tagIndexMapState {
    value: Map<number, number>;
    status: 'idle' | 'loading' | 'failed';
}

const initialtagIndexMap: tagIndexMapState = {
    value: new Map(),
    status: 'idle',
};
export const tagIndexMapReducer = createSlice({
    name: 'tagindex',
    initialState: initialtagIndexMap,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        initializetagIndexMap: (state, action) => {
            console.log("aa");
            state.value = action.payload;
        },
        setTagIndexMap: (state, action) => {
            console.log(state, action);
            
            if (state && !state?.value){
                state.value = new Map(); 
            }
            state.value.set(Number(action.payload.key), Number(action.payload.value)) ; 
            console.log(state, action, state.value);
        },
    },
});

export const { initializetagIndexMap, setTagIndexMap } = tagIndexMapReducer.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTagIndex = (state: RootState) => state.tagIndex.value;

export default tagIndexMapReducer.reducer;
