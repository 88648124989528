import { Slide, Snackbar } from "@mui/material";
import React, { useState } from "react";
import { IconButton } from "@mui/material"; 
import CloseIcon from '@mui/icons-material/Close';


export const withSnackbar = (WrappedComponent: any) => {
    return (props: any) => {
        const [open, setOpen] = useState(false);
        const [message, setMessage] = useState("I'm a custom snackbar");
        // const [duration, setDuration] = useState(2000);
        // const [severity, setSeverity] = useState( "success"); /** error | warning | info */

        const showMessage = (message: React.SetStateAction<string>, duration = 2000) => {
            setMessage(message);
            // setSeverity(severity);
            // setDuration(duration);
            setOpen(true);
        };

        const handleClose = (event: any, reason: string) => {
            if (reason === "clickaway") {
                return;
            }
            setOpen(false);
        };

        return (
            <>
                <WrappedComponent {...props} snackbarShowMessage={showMessage} />
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    autoHideDuration={2000}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Slide}
                    message={message ? message : ""}
                    action={
                        <React.Fragment>
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                sx={{ p: 0.5 }}
                                onClick={() => handleClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        </React.Fragment>
                    }
                />
            </>
        );
    };
};
