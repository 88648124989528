import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice';
import answerMapReducer from './redux/answerMapReducer';
import  currentPageReducer  from './redux/currentPageReducer';
import disableMapReducer from './redux/disableMapReducer';
import tagIndexMapReducer from './redux/tagIndexMapReducer';
import { enableMapSet } from 'immer';
import customerProfileReducer from './redux/customerProfileReducer';

enableMapSet()
export const store = configureStore({
  reducer: {
    // counter: counterReducer,
    currentPage: currentPageReducer,
    answerMap: answerMapReducer,
    customerProfile: customerProfileReducer,
    tagIndex: tagIndexMapReducer,
    disableMap: disableMapReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
