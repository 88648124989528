import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';


export interface disableMapState {
    value: Map<number, boolean>;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: disableMapState = {
    value: new Map(),
    status: 'idle',
};
export const disableIndexReducer = createSlice({
    name: 'answerMap',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        initializetagIndexMap: (state, action) => {
            state.value = action.payload;
        },
        setTagIndexMap: (state, action) => {
            console.log(state, action);
            state.value.set(Number(action.payload.key), action.payload.value);//= action.payload;
        },
    },
});

export const { initializetagIndexMap, setTagIndexMap } = disableIndexReducer.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectDisableMap = (state: RootState) => state.disableMap.value;

export default disableIndexReducer.reducer;
