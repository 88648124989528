const Config = {
    endPoint: 'https://api-finlit.virtueanalytics.com/',
    endPointProd: 'https://api.virtueanalytics.com/api/',
    providerId: '',
    orgname: '',
    uiConfig: '',
    serviceConfigs: '',
    ipedsId: '',
    preText: '',
    REACT_APP_API_KEY: "AIzaSyA2aD5Pw_l-yxt6l7nT0lNBIHCc0UioQLY",
    REACT_APP_AUTH_DOMAIN: "",
    REACT_APP_DATABASE_URL: "",
    REACT_APP_PROJECT_ID: "",
    REACT_APP_STORAGE_BUCKET: "",
    REACT_APP_MESSAGING_SENDER_ID: "",
    REACT_APP_APP_ID: "",
    REACT_APP_MEASUREMENT_ID: ""

}

// const firebaseConfig = {

//     apiKey: "AIzaSyA2aD5Pw_l-yxt6l7nT0lNBIHCc0UioQLY",

//     authDomain: "finlit-b6efc.firebaseapp.com",

//     projectId: "finlit-b6efc",

//     storageBucket: "finlit-b6efc.appspot.com",

//     messagingSenderId: "502557123334",

//     appId: "1:502557123334:web:9695add6e404ad5316f487",

//     measurementId: "G-TWDQ368HQ9",
//     clientId:
//     "788153552342-gkgingf8gsm5t1osoa7s7otiaca8kvf5.apps.googleusercontent.com"

// };

export default Config;