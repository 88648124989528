function AppAdvertiesment() {
    return (<>
        <div className="bg-white md:container mx-auto rounded my-8 pb-16   ">
            <div className="text-white drop-shadow-xl rounded-xl  px-24 pt-16  flex flex-row bg-sky-900 text-lg justify-between items-center ">
                <div className="flex flex-col mb-8 ">
                    <div className="text-4xl font-bold 	">Get the Finest App for  Financial Finesse </div>
                    <div className="flex flex-row mt-12 ">
                        <a href="https://play.google.com/store/apps/details?id=com.thefinlitproject.app&pli=1" target='_blank' rel="noreferrer">
                            <img src="https://cdn.virtueanalytics.com/cache/apple-store-btn.png" alt="" className="w-48"></img>
                        </a>
                        <a href="https://apps.apple.com/app/id1576101765#?platform=iphone" target="_blank" rel="noreferrer" className="ml-4">
                            <img src="https://cdn.virtueanalytics.com/cache/google-play-btn.png" className="w-48" alt=""></img>
                        </a>
                    </div>
                   
                </div>
                <div>
                        <img src="https://cdn.virtueanalytics.com/cache/appscreen-thefinlitprojectct.png" alt="" className="w-48"></img>
                    </div>
            </div>
        </div>
    </>);
}


export default AppAdvertiesment;