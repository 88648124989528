import { createSlice } from '@reduxjs/toolkit';
import { Answer } from '../../events/Model';
import { RootState } from '../store';
// import { fetchCount } from './counterAPI';



export interface answerMapState {
    value: Map<number, Answer>;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: answerMapState = {
    value: new Map(),
    status: 'idle',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// export const incrementAsync = createAsyncThunk(
//     'counter/fetchCount',
//     async (amount: number) => {
//         // const response = await fetchCount(amount);
//         // // The value we return becomes the `fulfilled` action payload
//         // return response.data;
//         return amount
//     }
// );

export const answerMapReducer = createSlice({
    name: 'answerMap',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        initializeAnswerMap: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.value = action.payload;
        },
        updateAnswerMap: (state, action) => {
            console.log(state, action);
            state.value.set(Number(action.payload.key), (action.payload.value));
            console.log(state, action, state.value);
            // state.value = action.payload;
        },
        removeFieldAnswerMap: (state, action) => { 
            console.log(action); 
            if (state.value.size && state.value.get(action.payload.key)) {
                state.value.delete(action.payload.key);  
            }
        }
    },
});

export const { initializeAnswerMap, updateAnswerMap, removeFieldAnswerMap } = answerMapReducer.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAnswerMap = (state: RootState) => state.answerMap.value;


export default answerMapReducer.reducer;
