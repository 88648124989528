import axios from "axios";
import Config from "../ui/const/Config";
// import { ReqMethod } from "../../common/Model";
let form;
let orgLoading = false;
const endPoint = Config.endPoint;
export function getForm(callback: Function) {
    axios.get(Config.endPoint + 'form/612f7b73-72cd-4521-a1b1-9805e0ebaa71/')
        .then(function (response) {
            // handle success
            form = response;
            console.log(form);
            callback(response.data)
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
        .then(function () {
            // always executed
        });
}


export function getOranization(serviceUrl: string) {
    console.log(serviceUrl);
    if (!orgLoading) {
        orgLoading = true;
        let body = {
            serviceConfig: {
                serviceType: 'IAR',
                serviceUrl: serviceUrl,
            },
        };
        axios({
            // Endpoint to send files
            url: `${endPoint}organisation/search`,
            method: "POST",
            headers: {
                "content-type": "application/json",
                // Add any auth token here
                // authorization: "your token comes here",
            },
            data: body,
        }).then((res) => {
            const dataResp = res.data[0]
            Config.providerId = dataResp.id;
            Config.orgname = dataResp.name;
            Config.uiConfig = dataResp.uiConfig;
            Config.ipedsId = dataResp.ipedsId;
            dataResp.serviceConfigs = dataResp.serviceConfigs.filter((element: any) => {
                return element.serviceUrl === serviceUrl && element.serviceType === 'IAR'
            });
            Config.serviceConfigs = dataResp.serviceConfigs[0];
            orgLoading = false;
        }).catch((err) => { console.log(err) });
    }
}

export function ForgotPass(username: string, callback: Function) {
    const body = {
        "organisationRef": {
            "id": Config.providerId,
            "name": Config.orgname
        },
        "userId": username
    }
    axios({
        // Endpoint to send files
        url: `${endPoint}iar/auth/forgotPassCode`,
        method: "POST",
        headers: {
            "content-type": "application/json",
        },
        data: body,
    }).then((res) => {
        console.log(res.data);
        callback({ error: false, resp: res.data });
    }).catch((err) => { console.log(err, err.response.status); callback({ error: true, status: err.response.status, resp: err.response }); });
}

export function CreateCustomerProfile(name: string, age: string, income: string, mobile: string, email: string, liabilities: string, callback: Function) {
    const body = {
       
        "firstName": name,
        "demographics": {
            "age": Number(age),
          
        },
        "contactDetail": {
            "email": email,
            "phone": Number(mobile)
        },
        "financialDetail": {
            "totalIncome": income,
            "liability": liabilities
        }, 
    }


    axios({
        // Endpoint to send files
        url: `${endPoint}customerProfile/setCustomerNature/create`,
        method: "POST",
        headers: {
            "content-type": "application/json",
        },
        data: body,
    }).then((res) => {
        console.log(res.data);
        callback({ error: false, resp: res.data });
    }).catch((err) => { 
        // console.log(err, err.response.status); 
        callback({ error: true, status: err?.response?.status, resp: err?.response }); 
    });
}
export function getMutualFund(amount: string, customerId: string, callback: Function) {
    const body = {

        "amount": Number(amount),
        "customerId": customerId
    }


    axios({
        // Endpoint to send files
        url: `${endPoint}customerProfile/tillSecondTopFunds`,
        method: "POST",
        headers: {
            "content-type": "application/json",
        },
        data: body,
    }).then((res) => {
        console.log(res.data);
        callback({ error: false, resp: res.data });
    }).catch((err) => {
        // console.log(err, err.response.status); 
        callback({ error: true, status: err?.response?.status, resp: err?.response });
    });
}
export function ResetPass(username: string, password: string, otp: string, callback: Function) {
    const body = {
        "organisationId": Config.providerId,
        "userId": username,
        "newPassCode": password,
        "otp": otp
    }
    axios({
        // Endpoint to send files
        url: `${endPoint}iar/auth/resetPassCode`,
        method: "POST",
        headers: {
            "content-type": "application/json",
        },
        data: body,
    }).then((res) => {
        console.log(res.data);
        callback({ error: false, resp: res.data });
    }).catch((err) => { console.log(err, err.response.status); callback({ error: true, status: err.response.status, resp: err.response }); });
}

export function RegisterUser(username: string, dob: string, callback: Function) {

    const body = {
        "organisationRef": {
            "id": Config.providerId,
            "name": Config.orgname
        },
        "dob": dob,
        "userId": username,
        // "passCode": password
    }
    axios({
        // Endpoint to send files
        url: `${endPoint}iar/auth`,
        method: "POST",
        headers: {
            "content-type": "application/json",
        },
        data: body,
    }).then((res) => {
        console.log(res.data);
        callback({ error: false, resp: res.data });
    }).catch((err) => { console.log(err, err.response.status); callback({ error: true, status: err.response.status, resp: err.response }); });
}

export function LoginUser(username: string, password: string, callback: Function) {
    localStorage.setItem("isLogin", "true")
    localStorage.setItem("userInfo", JSON.stringify({}));
    callback({ error: false, resp: {} });
    // const body = {
    //     "organisationId": Config.providerId,
    //     "userId": username,
    //     "passCode": password,
    //     "dob": dob,
    // }
    // axios({
    //     // Endpoint to send files
    //     url: `${endPoint}iar/auth/validateUser`,
    //     method: "POST",
    //     headers: {
    //         "content-type": "application/json",
    //     },
    //     data: body,
    // }).then((res) => {

    //     console.log(res.data);
    //     setUniqueId(res.data.uniqueId)
    //     if (res?.data?.passcode?.validity === 'VALID_CREDENTIALS' && res?.data?.email?.validity === 'VALID_CREDENTIALS') {
    //         const body1 = {
    //             "organisationId": Config.providerId,
    //             "uniqueId": res.data.uniqueId,
    //         }
    //         axios({
    //             // Endpoint to send files
    //             url: `${endPoint}iar/application/search/user`,
    //             method: "POST",
    //             headers: {
    //                 "content-type": "application/json",
    //             },
    //             data: body1,
    //         }).then((resp) => {
    //             console.log(resp.data);
    //             localStorage.setItem("isLogin", "true")
    //             localStorage.setItem("userInfo", JSON.stringify(resp.data));
    //             callback({ error: false, resp: resp.data });
    //         }).catch((err) => { console.log(err, err.response.status); callback({ error: true, status: err.response.status, resp: err.response }); });
    //     } else {
    //         callback({ error: true, status: '', resp: "Invalid Credentials" });
    //     }

    // }).catch((err) => { console.log(err, err?.response?.status); callback({ error: true, status: err?.response?.status, resp: err.response }); });
}

