import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Config from "../const/Config";
import { ForgotPass, getOranization, LoginUser, ResetPass } from "../../services/Api";
import { withSnackbar } from "../../events/hooks/useNotification";
import { InputLabel, TextField } from "@mui/material";
import { BootstrapInput, useFormInput } from "../../events/CodeHelper";

function Login(props: any) {
    const navigate = useNavigate();
    const classes = {
        pageBody: 'h-screen flex  bg-gray-200/75 absolute z-20 top-0 left-0 right-0 bottom',
        formContainer: 'w-full 	 m-auto  rounded-lg py-10 px-12 sm:px-24 md:px-48 lg:px-96 bg-sky-900 	-rotate-3',
        formyellow: 'bg-amber-300 rotate-3  sm:w-5/12 md:w-6/12 lg:w-8-/12	 m-auto p-0 shadow-xl',
        // formContainer: 'w-full 	 m-auto bg-white rounded-lg   	 py-10 px-24 bg-sky-900 	-rotate-3',
        // formyellow: ' bg-amber-300 rotate-3  w-5/12	 m-auto p-0 shadow-xl',
        formHeading: 'text-2xl font-medium text-primary mt-4 mb-12 text-center',
        btnContainer: 'flex justify-center items-center mb-2 ',
        btn: 'flex justify-center items-center mt-6 border-2  border-sky-800 bg-amber-300 text-lg  p-2 px-8 rounded-full mx-2 text-sky-900',
        inputClass: ' border border-gray-800  shadow-md w-full bg-white rounded-lg',
        inputLabel: 'mt-4  font-light text-white login-lb	text-left log-label ',
        inputGroup: 'flex flex-col justify-start px-8 ',
        title: 'text-xl font-bold italic text-center text-emerald-500 pb-6 mb-4 border-b-2 border-sky-800 w-full'
    };
    const username = useFormInput('');
    const password = useFormInput('');
    const otp = useFormInput('');
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [showForgotPass, setShowForgotPass] = useState(false);
    const [showResetPass, setShowResetPass] = useState(false);

    // handle button click of login form
    let { id } = useParams();
    useEffect(() => {
        if (!Config.providerId) {
            console.log("login");
            getOranization(window.location.href.split("/", 3).join("/") + '/' + id);
        }
    })
    // const handleRegister = () => {
    //     navigate('register', { replace: true });
    // }
    // const handleForgotPassword = () => {
    //     setShowForgotPass(!showForgotPass)
    // }
    const handleResetbtn = () => {
        setError("");
        console.log(username);
        setLoading(true);
        if (username.value) {
            ResetPass(username.value, password.value, otp.value, (resp: { error: any; }) => {
                console.log(resp);
                if (resp.error) {
                    setError("Error in processing Request, please Retry!");
                } else {
                    props.snackbarShowMessage('Password Reset Succesfully', 2000);
                    setShowResetPass(false); setShowForgotPass(false);
                }
                setLoading(false);
            });
        }
        else {
            setLoading(false);
            setError("Something went wrong. Please try again later.");
        }
    }

    const handleForgotbtn = () => {
        setError("");
        console.log(username);
        setLoading(true);
        if (username.value) {
            ForgotPass(username.value, (resp: { error: any; }) => {
                console.log(resp);
                if (resp.error) {
                    setError("Error in processing Request, please Retry!");
                } else {
                    props.snackbarShowMessage('Otp send on your mail, please fill', 2000);
                    setShowResetPass(true);
                }
                setLoading(false);
            });
        }
        else {
            setLoading(false);
            setError("Something went wrong. Please try again later.");
        }
    }
    const handleLogin = () => {
        setError("");
        setLoading(true);
        if (username.value && password.value) {
            LoginUser(username.value, password.value, (resp: any) => {
                console.log(resp);
                setLoading(false);
                if (resp.error) {
                    setError("Something went wrong. Please try again later.");
                } else {
                    props.snackbarShowMessage('Welcome', 2000);
                    navigate('../home', { replace: true });
                }
            })
        }
        else {
            setLoading(false);
            setError("Please fill all field");
        }
    }

    // const googleLogin = () => {
    //     const onSuccess = (res: { tokenId: any; getAuthResponse: () => { (): any; new(): any; id_token: any; }; profileObj: { name: any; }; tokenObj: { access_token: any; }; }) => {
    //         console.log("res:", res);
    //         console.log("res.tokenId:", res.tokenId);
    //         console.log(
    //             "res.getAuthResponse().id_token",
    //             res.getAuthResponse().id_token
    //         );
    //         console.log("Login Success: currentUser:", res.profileObj);
    //         console.log("token:", res.tokenObj);
    //         fetch(
    //             `https://dialogflow.googleapis.com/v2/projects/-/agent:search?key=${Config.REACT_APP_API_KEY}`,
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${res.tokenObj.access_token}`,
    //                     Accept: "application/json"
    //                 }
    //             }
    //         )
    //             .then((res) => res.json())
    //             .then((data) => console.log("data", data));
    //         alert(
    //             `Logged in successfully welcome ${res.profileObj.name} 😍. \n See console for full profile object.`
    //         );
    //         // refreshTokenSetup(res);
    //     };

    //     const onFailure = (res: any) => {
    //         console.log("Login failed: res:", res);
    //         alert(
    //             `Failed to login. `
    //         );
    //     };
    // }

    return (
        <div className="">
            <video muted autoPlay controls className="fixed z-10  hidden sm:block">
                <source src="https://cdn.virtueanalytics.com/cache/thefinlitproject-loginpage.mp4" type="video/mp4 "  /> </video>

            <div className={classes.pageBody}>

                {!showForgotPass && <div className={classes.formyellow}> <div className={classes.formContainer}>
                    <div className={classes.title}>
                        <img alt="" src="https://cdn.virtueanalytics.com/cache/finlit-logo.png" className="w-64 mx-auto "></img></div>
                    {/* <h1 className={classes.formHeading}>
                    Log in to your account
                </h1> */}
                    <div className={classes.inputGroup}>
                        <InputLabel htmlFor="email" className={classes.inputLabel} >
                            Email
                        </InputLabel>
                        <BootstrapInput className={classes.inputClass} defaultValue="" id="email" fullWidth  type="text" margin="dense"  {...username} />
                    </div >
                    {/* <TextField className={classes.inputClass} id="email" label="Email" variant="outlined" fullWidth type="email" margin="dense"  {...username} /> */}
                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}
                    adapterLocale={enLocale}>
                    <DatePicker
                        label="DOB"
                        value={dob}
                        onChange={(newValue: any) => {
                            setDob(newValue);
                        }}
                        renderInput={(params: TextFieldProps) => <TextField {...params} margin="dense" fullWidth />}
                    />
                </LocalizationProvider> */}
                    <div className={classes.inputGroup}>
                        <InputLabel htmlFor="password" className={classes.inputLabel}>
                            Password
                        </InputLabel>
                        <BootstrapInput className={classes.inputClass} defaultValue="" id="password" fullWidth type="password" margin="dense"  {...password} />
                    </div >
                    {/* <TextField className={classes.inputClass} id="password" label="password" variant="outlined" fullWidth type="password" margin="dense"  {...password} /> */}
                    {error &&
                        <>
                            <small style={{ color: 'red' }}>
                                {error}
                            </small>
                            <br />
                        </>
                    }
                    <br />
                    {/* <h1 className={classes.formHeading} onClick={googleLogin}>
                    Log in with GOOGLE
                </h1>
                <GoogleLogin
                    clientId={Config.}
                    buttonText="Login"
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                    scope={
                        "https://www.googleapis.com/auth/cloud-platform https://www.googleapis.com/auth/dialogflow"
                    }
                    cookiePolicy={"single_host_origin"}
                    style={{ marginTop: "100px" }}
                    isSignedIn={true}
                /> */}
                    <div className={classes.btnContainer}>
                        <button type='submit' onClick={handleLogin} className={classes.btn}>
                            {!loading && 'Submit'}{loading && 'Loading'}
                        </button>
                        {/* <button onClick={handleRegister} className={classes.btn}>
                        Register
                    </button> */}
                    </div>
                    {/* <div onClick={handleForgotPassword}>Forgot Password</div> */}
                </div> </div>}
                {(showForgotPass && !showResetPass) && <div className={classes.formContainer}>
                    <div className={classes.title}>Forgot Password</div>
                    <h1 className={classes.formHeading}>
                        Log in to your account
                    </h1>

                    <div className={classes.inputGroup}>
                        <InputLabel htmlFor="email" className={classes.inputLabel}>
                            Email
                        </InputLabel>
                        <BootstrapInput className={classes.inputClass} defaultValue="" id="email" fullWidth type="text" margin="dense"  {...username} />
                    </div >

                    <div className={classes.inputGroup}>
                        <InputLabel htmlFor="password" className={classes.inputLabel}>
                            Password
                        </InputLabel>
                        <BootstrapInput className={classes.inputClass} defaultValue="" id="password" fullWidth type="password" margin="dense"  {...password} />
                    </div >
                    {error &&
                        <>
                            <small style={{ color: 'red' }}>
                                {error}
                            </small>
                            <br />
                        </>
                    }
                    <br />

                    <div className={classes.btnContainer}>
                        <button type='submit' onClick={handleLogin} className={classes.btn}>
                            {!loading && 'Submit'}{loading && 'Loading'}
                        </button>

                    </div>
                </div>
                }
                {(showForgotPass && !showResetPass) && <div className={classes.formContainer}>
                    <div className={classes.title}>Forgot Password</div>
                    <h1 className={classes.formHeading}>
                        Log in to your account
                    </h1>
                    <TextField id="email" label="Email" variant="outlined" fullWidth type="email" margin="dense"  {...username} />
                    {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
                    <div className={classes.btnContainer}>
                        <button type='submit' onClick={handleForgotbtn} className={classes.btn}>
                            {!loading && 'Forgot Password'}{loading && 'Loading'}
                        </button>
                    </div>
                    <div onClick={() => setShowForgotPass(false)}>Login</div>
                </div>}
                {showResetPass && <div className={classes.formContainer}>
                    <div className={classes.title}>Forgot Password</div>
                    <h1 className={classes.formHeading}>
                        Log in to your account
                    </h1>
                    <TextField id="otp" label="otp" variant="outlined" fullWidth type="text" margin="dense"  {...otp} />
                    <TextField id="password" label="New Password" variant="outlined" fullWidth type="password" margin="dense"  {...password} />
                    {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
                    <div className={classes.btnContainer}>
                        <button type='submit' onClick={handleResetbtn} className={classes.btn}>
                            {!loading && 'Reset Password'}{loading && 'Loading'}
                        </button>
                    </div>
                </div>}
            </div>
        </div>
    );
}


export default withSnackbar(Login);